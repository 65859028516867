import React, { useState, useRef, useEffect } from "react";
import "../styles/rightcolumn.css";

function RightColumn(parentProp) {
  const message = [`🅃🅄🄳🄸🄴🄽.🄽🄴🅃`, `♡ ♥💕❤😘`, `🅥🅘🅔🅣🅝🅐🅜🅔🅢🅔`, `👋≧◉ᴥ◉≦`, `🌠💘🔥`];
  const index = parentProp.index;

  const [topPosition, settopPosition] = useState("10px");

  function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  const activeSlideRef = useRef(null);

  useEffect(() => {
    let count = 400;
    let counterIncrement = -4;
    let counter = setInterval(timer, 1000);

    function timer() {
      count = count + counterIncrement;
      if (count == 0 || count == 400) {
        counterIncrement = -counterIncrement;
      }

      activeSlideRef.current.style.top = `${count}px`;
      //console.log(activeSlideRef.current.style.top);
      //https://stackoverflow.com/questions/442404/retrieve-the-position-x-y-of-an-html-element-relative-to-the-browser-window
      console.log(`getBoundingClientRect ` + getOffset(activeSlideRef.current).top)
    }
  }, []);

  const animationClassName = [
    `animate__flash`,
    `animate__shakeY`,
    `animate__wobble`,
    `animate__flash`,
    `animate__bounceInUp`,
  ];

  //const styleDomain = { top: "100px" };

  return (
    <div className="menu-background-right">
      <p
        ref={activeSlideRef}
        className="vertical-headline"
        // style={styleDomain}
      >
        ©hoccoban.com
      </p>
      <p
        className={`item-right animate__animated style__right-item ${animationClassName[index]}`}
      >
        {message[index]}
      </p>
    </div>
  );
}

export default RightColumn;
